import React, { Component } from "react";
// We are using axios as the http library
import axios from "axios";
import * as styles from "./FileUpload.module.css";
import Image from '../elements/Image';

export default class FileUpload extends Component {
    // fileToUpload contains the actual file object
    // uploadSuccess becomes true when the file upload is complete
    state = {
        fileToUpload: undefined,
        uploadSuccess: undefined,
        inProgress: false,
        error: undefined
    };
uploadFile() {
        this.setState({inProgress: true});
        // When the upload file button is clicked, 
        // first we need to get the presigned URL
        // URL is the one you get from AWS API Gateway
        axios(
            "https://hycondyl6g.execute-api.us-east-1.amazonaws.com/live/claire-memorial-upload?fileName=" +
                this.state.fileToUpload.name
        ).then(response => {
            // Getting the url from response
            const url = response.data.fileUploadURL;
   
            // Initiating the PUT request to upload file    
            axios({
                method: "PUT",
                url: url,
                data: this.state.fileToUpload,
                headers: { "Content-Type": "multipart/form-data" }
            })
                .then(res => {
                    this.setState({
                        uploadSuccess: "File upload successfull",
                        error: undefined,
                        fileToUpload: undefined,
                        inProgress: false
                    });
                })
                .catch(err => {
                    this.setState({
                        error: "Error Occured while uploading the file",
                        uploadSuccess: undefined,
                        fileToUpload: undefined,
                        inProgress: false
                    });
                });
        });
    }
    render() {
        return (<React.Fragment>      <div id="file_upload" className="center-content"><h2>Upload Photo or Video</h2></div>
        
            <div className={styles.fileUploadCont}>
                <div className={styles.header}>
                    Choose a video or photo to share
                </div>
                <div>
                    <form>
                        <div className="form-group">
                            <input
                                style={{"color":"transparent"}}
                                type="file"
                                accept="image/*,video/*"
                                className="form-control-file"
                                id="fileUpload"
                                onChange={e => {
                                    this.setState({
                                        fileToUpload: e.target.files[0],
                                        uploadSuccess: undefined
                                    });
                                }}
                            />
                            
                        </div>
                        <div className="form-group">
                            {this.state.fileToUpload && !this.state.inProgress ? (
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={e => {
                                            this.uploadFile();
                                        }}
                                    >
                                        Upload your file
                                    </button>
                                ) : null}
                        </div>
                        {!!this.state.inProgress ? 
                        <Image
                  src={require('./../../assets/images/spinner.gif')}
                  alt="Loading..."
                  width={50}
                  height={50} /> : ''}
                        <div className="form-group">
                            <strong>
                                {this.state.uploadSuccess
                                    ? "File Upload Successfully"
                                    : ""}
                            </strong>
                        </div>
                    </form>
                </div>
            </div></React.Fragment>
        );
    }
}