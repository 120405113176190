import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import axios from "axios";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    'center-content'
  );  

  const [textInfo, setTextInfo] = useState("");
  const [submissionName, setSubmissionName] = useState("");
  const [nameRequired, setNameRequired] = useState("");
  const [showThanks, setShowThanks] = useState(false);
  const [email, setEmail] = useState("");


  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
        <h2 style={{"margin-top": "0px"}}>Share Your Memory</h2>
        {showThanks && <h3 style={{"margin-top": "0px"}}>Thank you for your submission!</h3>}
        {!showThanks && <React.Fragment><h4 style={{"margin-bottom": "10px"}}>Story</h4>
        <textarea style={{"height": "250px", "width": "90%"}} onChange={(e) => setTextInfo(e.target.value)} id="claire_story"></textarea>
        <br/>
        {nameRequired && <React.Fragment><span style={{"color": "red"}}>Please Include Your Name</span><br/></React.Fragment>}
        <h4 style={{"margin-bottom": "10px"}}>Name</h4>
        <input style={{"height": "40px"}} id="claire_story_name" onChange={(e) => setSubmissionName(e.target.value)}></input>
        <br/>
        <h4 style={{"margin-bottom": "10px"}}>Email</h4>
        <input style={{"height": "40px"}} id="claire_story_name" onChange={(e) => setEmail(e.target.value)}></input>
        <br/>
        <br/>
        <small style={{"color":"white"}}>Your email is optional and will be used to send memorial updates.</small>
        <br/>
        <br/>
        <button class="button button-primary button-wide-mobile" id="submit_story" onClick={() => {
          if (!submissionName) {
            setNameRequired(true);
            return;
          }
          setNameRequired(false);
          if (!textInfo) {
            return;
          }
          console.log(textInfo);
          axios({
            method: "PUT",
            url: 'https://pp1nd3ake2.execute-api.us-east-1.amazonaws.com/live/write-claire-memory',
            data: {text: textInfo, name: submissionName, email: email},
            headers: { "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
            'Access-Control-Allow-Credentials': true, }
          })
            .then(res => {
                setTextInfo("");
                setEmail("");
                setSubmissionName("");
                setShowThanks(true);
            })
            .catch(err => {
                console.log(err);
            });


        }}>Submit</button>
        <br/><br/></React.Fragment>}
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;