import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Testimonial from './Testimonial';
import Shuffle from '../../utils/Shuffle';
const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Photos and Memories',
    paragraph: 'A few photos of Claire that capture her best.'
  };



  const generateSequenceArray = (num) => {
    const arr = [];
    for (let i = 0; i < num; i++) {
      arr.push(num);
    }
    return arr;

  }

  const pics = Shuffle([
    { headline: "Claire and Baby Bekah", caption: "Always a doting mother, Claire's greatest joy in life was her daughter, Bekah.", image: require('./../../assets/images/c2.jpg') },
    { headline: "Proud mother and daughter.", caption: "Wedding of Anne Spevack and Christoph Neyer, 2018", image: require('./../../assets/images/c1.jpg') },
    { headline: "Claire and her siblings", caption: "A breathtaking beauty who always stood out from the crowd.", image: require('./../../assets/images/c4.jpg') },
    { headline: "With sister-in-law Nancy Callahan", caption: "", image: require('./../../assets/images/c8.png') },
    { headline: "An incredulous look", caption: "Always a sharp dresser, too!", image: require('./../../assets/images/c6.png') },
    { headline: "Age 5", caption: "1959", image: require('./../../assets/images/c7.png') },
    { headline: "With close friend Elizabeth Anderson", caption: "", image: require('./../../assets/images/c9.png') },
  ]);

  const testimonialOrder = [];


  const picOrder = [];

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            {pics.map((pic, index) => (
              <div className="split-item">
                <div className={`split-item-content center-content-mobile reveal-from-${ index % 2 === 0 ? 'left' : 'right'}`} data-reveal-container=".split-item">
                  <h3 className="mt-0 mb-12">
                    {pic.headline || ''}
                    </h3>
                  <p className="m-0">
                    {pic.caption || ''}
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={pic.image}
                    alt={pic.headline || ''}
                    width={300}
                    height={300} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;