import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import axios from "axios";
import ShowMoreText from 'react-show-more-text';
import Shuffle from '../../utils/Shuffle';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Claire Memories',
    paragraph: 'Submit your favorite (short) Claire memories and anecdotes and we will display them here!'
  };

  const [textInfo, setTextInfo] = useState("");
  const [submissionName, setSubmissionName] = useState("");
  const [nameRequired, setNameRequired] = useState("");
  const [showThanks, setShowThanks] = useState(false);
  const [testimonials, setTestimonials] = useState(null);

  if (testimonials === null) {
    setTestimonials([]);
    axios({
      method: "GET",
      url: 'https://pp1nd3ake2.execute-api.us-east-1.amazonaws.com/live/claire-memories',
      headers: { "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
      'Access-Control-Allow-Credentials': true, }
    }).then(result => setTestimonials(Shuffle(result.data)));
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
          {(testimonials || []).map(testimonial => (
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <div className="testimonial-item-content">
                  <p className="text-sm mb-0 inner-quote" style={{whiteSpace: "pre-wrap"}}>
                    {testimonial.description.length >= 440 ? 
                      <ShowMoreText lines={16}>
                        {testimonial.description}
                      </ShowMoreText> 
                        :
                      testimonial.description
                    }
                  </p>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">{testimonial.name}</span>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;