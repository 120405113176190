import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 reveal-from-bottom" data-reveal-delay="200">
              Remembering...<br/><span className="text-color-primary">Claire Callahan Goodman</span>
            </h1>
            <Image
                className="has-shadow"
                src={require('./../../assets/images/claire-header.jpg')}
                alt="Hero"
                width={330}
                style={{"opacity": "75%"}}
                height={375} />
            <h2 className="mt-4 mb-16">1954-2021</h2>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                This web page serves as a digital memorial for Claire Callahan Goodman -- beloved mother, sister, colleague, and friend -- and to collect video, photo, and text memories and anecdotes. 
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="#file_upload">
                    Share memories
                  </Button>
                  <Button tag="a" color="dark" target="_blank" wideMobile href="https://www.legacy.com/us/obituaries/sfgate/name/claire-goodman-obituary?pid=198751975">
                    View obituary
                  </Button>
                  <Button tag="a" color="dark" href="#memorial_info" wideMobile>
                    Watch memorial
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;